<template>
  <div>
    <b-card
      title="Bedrijfsinformatie"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormOrganisation1Observer"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit1)"
        >
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="ID"
                  label-for="id"
                >
                  <b-form-input
                    id="id"
                    v-model="organisationData.id"
                    readonly="readonly"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="name"
                  rules="required"
                >
                  <b-form-group
                    label="Naam"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="organisationData.name"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="status"
                  rules="required"
                >
                  <b-form-group
                    label="Status"
                    label-for="status"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="status"
                      v-model="organisationData.active"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Field: Email -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="organisationData.email"
                      type="email"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Mobile -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="phoneNumber"
                  rules="required|phoneNumber"
                >
                  <b-form-group
                    label="Telefoonnummer"
                    label-for="phoneNumber"
                  >
                    <b-form-input
                      id="phoneNumber"
                      v-model="organisationData.phoneNumber"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-button
              v-if="$can('update', 'organisation')"
              class="mt-1"
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Opslaan
            </b-button>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card-actions
      :action-collapse="true"
      :action-refresh="false"
      :action-close="false"
      :collapsed="true"
      title="Adres"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormOrganisation2Observer"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit2)"
        >
          <b-card-body>
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="5"
              >
                <validation-provider
                  #default="validationContext"
                  name="postalCode"
                  rules="required|postalcode"
                >
                  <b-form-group
                    label="Postcode"
                    label-for="postalCode"
                  >
                    <b-form-input
                      id="postalCode"
                      v-model="organisationData.postalCode"
                      v-debounce:500ms="getAddress"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="houseNumber"
                  rules="required|integer"
                >
                  <b-form-group
                    label="Huisnummer"
                    label-for="houseNumber"
                  >
                    <b-form-input
                      id="houseNumber"
                      v-model="organisationData.houseNumber"
                      v-debounce:500ms="getAddress"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="houseNumberAddition"
                  rules=""
                >
                  <b-form-group
                    label="Huisnummertoevoeging"
                    label-for="houseNumberAddition"
                  >
                    <b-form-input
                      id="houseNumberAddition"
                      v-model="organisationData.houseNumberAddition"
                      v-debounce:500ms="getAddress"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="street"
                  rules="required"
                >
                  <b-form-group
                    label="Straat"
                    label-for="street"
                  >
                    <b-form-input
                      id="street"
                      v-model="organisationData.street"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-spinner
                      v-if="showAddressSpinner"
                      style="position:absolute; top:26px;right:20px"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="city"
                  rules="required"
                >
                  <b-form-group
                    label="Plaats"
                    label-for="city"
                  >
                    <b-form-input
                      id="city"
                      v-model="organisationData.city"
                      :state="getValidationState(validationContext)"
                      :disabled="true"
                    />
                    <b-spinner
                      v-if="showAddressSpinner"
                      style="position:absolute; top:26px;right:20px"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-button
              v-if="$can('update', 'organisation')"
              class="mt-1"
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Opslaan
            </b-button>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </b-card-actions>
    <b-card-actions
      :action-collapse="true"
      :action-refresh="false"
      :action-close="false"
      :collapsed="true"
      title="Flows"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormOrganisation3Observer"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit3)"
        >
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group label="Toegankelijke flows">
                  <v-select
                    v-model="organisationData.flows"
                    dir="ltr"
                    multiple
                    label="name"
                    :reduce="val => val.id"
                    :options="flowsOptions"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-button
              v-if="$can('update', 'organisation')"
              class="mt-1"
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Opslaan
            </b-button>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </b-card-actions>
    <b-card-actions
      :action-collapse="true"
      :action-refresh="false"
      :action-close="false"
      :collapsed="true"
      title="Labels"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormOrganisation4Observer"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit4)"
        >
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group label="Toegankelijke labels">
                  <v-select
                    v-model="organisationData.suppliers"
                    dir="ltr"
                    multiple
                    label="name"
                    :reduce="val => val.id"
                    :options="suppliersOptions"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-button
              v-if="$can('update', 'organisation')"
              class="mt-1"
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Opslaan
            </b-button>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </b-card-actions>
    <b-card>
      <b-row>
        <b-col>
          <b-button
            variant="secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="button"
            :to="{ name: 'apps-organisations' }"
          >
            terug
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback, BSpinner, BCard, BCardFooter, BCardBody,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, email, integer, phoneNumber,
} from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import useOrganisationsList from '@/views/pages/organisations/useOrganisationsList'
import Ripple from 'vue-ripple-directive'
import { getDirective } from 'vue-debounce'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  directives: {
    Ripple,
    debounce: getDirective(),
  },
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BSpinner,
    BCardActions,
    BCard,
    BCardBody,
    BCardFooter,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    organisationData: {
      type: Object,
      required: true,
      default: () => {},
    },
    statusOptions: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    countryOptions: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    districtOptions: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    externalFinanceTypeOptions: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
  data() {
    return {
      productCategorieData: [
        { name: 'Offerte', id: 1 },
        { name: 'Overeenkomst', id: 2 },
      ],
      companyData: [],
      value: [],
      flowsOptions: [],
      suppliersOptions: [],
      showAddressSpinner: false,
    }
  },
  beforeMount() {
    store
      .dispatch('app-organisation-edit/fetchOrganisations', {
        sort: 'name',
      })
      .then(response => {
        this.companyData = response.data.items.filter(opt => String(opt.id) !== router.currentRoute.params.id)
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de organisaties',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    store
      .dispatch('app-organisation-edit/fetchFlows', {
        sort: 'name',
      })
      .then(response => {
        this.flowsOptions = response.data.items
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de flows',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    store
      .dispatch('app-organisation-edit/fetchSuppliers', {
        sort: 'name',
      })
      .then(response => {
        this.suppliersOptions = response.data.items
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de labels',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    getAddress() {
      const somethingChanged = []

      if (!this.organisationData.postalCode || !this.organisationData.houseNumber) {
        return
      }

      if (this.organisationData.postalCode) {
        somethingChanged.push(true)
      }

      if (this.organisationData.houseNumber) {
        somethingChanged.push(true)
      }

      if (this.organisationData.houseNumberAddition) {
        somethingChanged.push(true)
      }

      if (somethingChanged.includes(true)) {
        this.showAddressSpinner = true
        this.organisationData.street = null
        this.organisationData.city = null
        this.organisationData.province = null
        store.dispatch('app-organisation-edit/fetchCorrespondenceAddress', this.organisationData)
          .then(response => {
            this.organisationData.street = response.data.street
            this.organisationData.city = response.data.city
            this.organisationData.province = response.data.province
            this.showAddressSpinner = false
          })
          .catch(() => {
            this.showAddressSpinner = false
          })
      }
    },
    onSubmit1() {
      this.$emit('update-organisation',
        {
          id: this.organisationData.id,
          name: this.organisationData.name,
          active: this.organisationData.active,
          email: this.organisationData.email,
          phoneNumber: this.organisationData.phoneNumber,
        })
    },
    onSubmit2() {
      this.$emit('update-organisation',
        {
          id: this.organisationData.id,
          postalCode: this.organisationData.postalCode,
          houseNumber: this.organisationData.houseNumber,
          houseNumberAddition: this.organisationData.houseNumberAddition,
          street: this.organisationData.street,
          city: this.organisationData.city,
        })
    },
    onSubmit3() {
      this.$emit('update-organisation',
        {
          id: this.organisationData.id,
          flows: this.organisationData.flows,
        })
    },
    onSubmit4() {
      this.$emit('update-organisation',
        {
          id: this.organisationData.id,
          suppliers: this.organisationData.suppliers,
        })
    },
  },
  setup() {
    const {
      typeOptions,
    } = useOrganisationsList()
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      avatarText,
      typeOptions,
      required,
      email,
      integer,
      phoneNumber,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.invalid{
  .card-title{
    color: #ea5455;
  }
}
</style>
